<template>
    <div>
        <input type="file" id="photoFileUpload" style="display: none" />
        <textarea :id="Id"></textarea>
    </div>
</template>
<script>
import '../../../public/static/tinymce/tinymce';
export default {
    name: 'ImcoderTinyMce',
    props: {
        value: {
            default: '',
            type: String,
        },
        config: {
            type: Object,
            default: () => {
                return {
                    theme: 'modern',
                    height: 600,
                };
            },
        },
        url: {
            default: '',
            type: String,
        },
        accept: {
            default: 'image/jpeg, image/png',
            type: String,
        },
        maxSize: {
            default: 10,
            type: Number,
        },
    },
    watch: {
        value(val) {
            if (this.flag) {
                this.setContent(val);
            }
            this.flag = true;
        },
    },
    data() {
        // const Id = Date.now();
        return {
            Id: 1,
            flag: true,
            myEditor: null,
            DefaultConfig: {
                // GLOBAL
                language: 'zh_CN', //汉化
                autoresize_min_height: 200,
                autoresize_max_height: 400,
                theme: 'modern', //默认主题
                menubar: false,
                toolbar: `  forecolor backcolor |  link  | preview `, //需要的工具栏
                target_list: [
                    { title: '当前窗口', value: '' },
                    { title: '新窗口', value: '_blank' },
                ],
                plugins: `
                autoresize
            importcss
            fullscreen
            link
            preview
            paste
            ax_wordlimit
          `,
                // CONFIG
                forced_root_block: 'p',
                force_p_newlines: true,
                importcss_append: true,
                // CONFIG: ContentStyle 这块很重要， 在最后呈现的页面也要写入这个基本样式保证前后一致， `table`和`img`的问题基本就靠这个来填坑了
                content_style: `
            *                         { padding:0; margin:0; }
            html, body                { height:100%; }
            img                       { max-width:100%; display:block;height:auto; }
            a                         { text-decoration: none; }
            iframe                    { width: 100%; }
            p                         { line-height:1.6; margin: 0px; }
            table                     { word-wrap:break-word; word-break:break-all; max-width:100%; border:none; border-color:#999; }
            .mce-object-iframe        { width:100%; box-sizing:border-box; margin:0; padding:0; }
            ul,ol                     { list-style-position:inside; }
          `,
                insert_button_items: 'image link | inserttable',
                // CONFIG: Font
                fontsize_formats: '12px',
                // FontSelect
                font_formats: `
            微软雅黑=微软雅黑`,
                // Image
                // imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
            },
        };
    },
    methods: {
        setContent(content) {
            if (!content) {
                return;
            }
            this.myEditor.setContent(content);
        },
        getContent() {
            return this.myEditor.getContent();
        },
        init() {
            const self = this;
            window.tinymce.init({
                // 默认配置
                ...this.DefaultConfig,
                // 挂载的DOM对象
                selector: `#${this.Id}`,
                ax_wordlimit_num: 100,
                ax_wordlimit_callback: function (editor, txt, num) {
                    self.$message.error('当前字数：' + txt.length + '，限制字数：' + num);
                },
                paste_preprocess: function (plugin, args) {
                    // replace copied text with empty string
                    args.content = '';
                },
                // prop内传入的的config
                ...this.config,
                setup: (editor) => {
                    self.myEditor = editor;
                    editor.on('init', (ed) => {
                        self.loading = false;
                        ed.target.editorCommands.execCommand('fontName', false, '微软雅黑');
                        if (self.value) {
                            editor.setContent(self.value);
                        } else {
                            editor.setContent('');
                        }
                    });
                    // 抛出 'input' 事件钩子，同步value数据
                    editor.on('input change undo redo execCommand', () => {
                        self.flag = false;
                        self.$emit('input', editor.getContent());
                    });
                },
            });
        },
    },
    mounted() {
        window.tinymce.baseURL = '/static/tinymce';
        window.tinymce.suffix = '.min';
        this.init();
    },
    beforeDestroy() {
        // 销毁tinymce
        window.tinymce.remove(`#${this.Id}`);
    },
};
</script>
